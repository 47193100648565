"use client";

import * as React from "react";
import Link from "next/link";
import { Link as LinkIcon, Mail, Share2 } from "lucide-react";

import { Page } from "@/types";
import { cn } from "@/lib/utils";
import { SITE_URL } from "@/lib/utils/index";

import { AddNote } from "@/components/add-note";
import { Button } from "@/components/ui/button";
import ReferEarn from "@/components/refer-earn";
import { SaveButton } from "@/components/saved-pages";

interface StudySummaryActionBarProps extends React.HTMLAttributes<HTMLDivElement> {
	headline: string;
	id: string;
	isFavorite: boolean;
	showFavorite: boolean;
	onFavorited?: (id: string, isFavorite: boolean) => void;
	className?: string;
}

interface ActionButtonProps {
	icon: React.ReactNode;
	label: string;
	onClick?: () => void;
	disabled?: boolean;
}

export function StudySummaryActionBar({
	headline,
	id,
	showFavorite,
	className,
}: StudySummaryActionBarProps) {
	const page: Page = {
		id,
		url: `/research-feed/study/${id}/`,
		type: "study-summaries",
		slug: id,
		name: headline,
	};

	function openMail() {
		window.location.href = `mailto: support@examine.com?subject=${headline} there&body=Click here to get the study info - ${SITE_URL}/research-feed/study/${id}/?requirelogin=1`;
	}

	const ActionButton: React.FC<ActionButtonProps> = ({
		icon,
		label,
		onClick,
		disabled = false,
	}) => (
		<Button variant="text" size="sm" className={btnStyle} onClick={onClick} disabled={disabled}>
			{icon}
			<span className="hidden text-sm md:inline lg:text-menu">{label}</span>
		</Button>
	);

	const btnStyle = `gap-1 px-3 py-1 font-medium hover:bg-primary hover:text-white md:gap-2`;

	return (
		<div
			className={cn(
				className,
				"flex items-center justify-between rounded-md bg-[#faf9ff] px-2 py-1 text-primary 2xl:px-16"
			)}
			onClick={(e) => e.stopPropagation()}
		>
			<ReferEarn
				desc={headline}
				url={`${SITE_URL}/refer/jlaaxl1?loc=/research-feed/study/${id}/?requirelogin=1`}
			>
				<ActionButton icon={<Share2 className="size-4" />} label="Refer and Earn" />
			</ReferEarn>

			<Link href={`/research-feed/study/${id}/?requirelogin=1`}>
				<ActionButton icon={<LinkIcon className="size-4" />} label="Link" />
			</Link>

			{showFavorite ? (
				<SaveButton
					page={page}
					variant="text"
					size="sm"
					className={cn(btnStyle, "text-sm lg:text-menu")}
				/>
			) : null}

			<ActionButton icon={<Mail className="size-4" />} label="Email" onClick={openMail} />

			<AddNote page={page} variant="text" size="sm" className={btnStyle} />
		</div>
	);
}
