"use client"

import { useState } from "react";

interface useExpandCollapseResult {
	setCardExpanded: (cardId: string, expanded: boolean) => void;
	setAllExpanded: () => void;
	setAllCollapsed: () => void;
	isCardExpanded: (cardId: string) => boolean;
}

export function useExpandCollapse(cardIds: string[]): useExpandCollapseResult {
	const [expanded, setExpanded] = useState(() => {
		const initialState: { [key: string]: boolean } = {};
		for (const id of cardIds) {
			// collapsed by default
      initialState[id] = false;
    }
		return initialState
	})

	const setCardExpanded = (cardId: string, expanded: boolean) => {
		setExpanded((prevState) => {
			const newState = { ...prevState }

			newState[cardId] = expanded

			return newState
		})
	}

	const setAllExpanded = () => {
		setExpanded((prevState) => {
			const newState = { ...prevState }

			for (const id of cardIds) {
				newState[id] = true
			}

			return newState
		})
	}

	const setAllCollapsed = () => {
		setExpanded((prevState) => {
			const newState = { ...prevState }

			for (const id of cardIds) {
				newState[id] = false
			}

			return newState
		})
	}

	const isCardExpanded = (cardId: string) => {
		return expanded[cardId]
	}

	return { setCardExpanded, setAllExpanded, setAllCollapsed, isCardExpanded };
}
